<template>
  <Layout>
    <PageHeader :items="items"/>
    <TermsnCondition>
    </TermsnCondition>
    <div class="row">
      <!-- For owner and branh login -->
      <div class="col-md-2" style="position: absolute;right: 46%;top: 75px;"
           v-if="this.$storageData.profile.empTypeID==5">

        <label>Select Country </label>
        <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                     @input="getCorporateCities();" :show-labels="false"></multiselect>
      </div>
      <div class="col-md-2" style="position: absolute;right: 31%;top: 75px;"
           v-if="this.$storageData.profile.empTypeID==5">

        <label>Select City </label>
        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                     @input="getCorporateBranchByCorpID();" :show-labels="false"></multiselect>
      </div>

      <div class="col-md-3" style="position: absolute;right: 7.5%;top: 75px;"
           v-if="this.$storageData.profile.empTypeID==5">
        <label>Select Corporate Branch </label>
        <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                     @input="onchangeBranch();" :show-labels="false"></multiselect>
      </div>
      <!--- PRINCIPLE --->
      <!--FILTER START--->
      <div class="card" style="margin-top:-30px">
        <div class="card-body">
          <div class="inner mb-2 row">
            <!-- For owner and branh login -->
            <div class="col-md-2" style="width:16%">

              <label>Select Country </label>
              <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                           @input="getCorporateCities();" :show-labels="false"></multiselect>
            </div>
            <div class="col-md-2" style="width:16%">

              <label>Select City </label>
              <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                           @input="getCorporateBranchByCorpID();" :show-labels="false"></multiselect>
            </div>
            <div class="col-md-2" style="width:16%">

              <label>Select Corporate Branch </label>
              <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                           :show-labels="false"></multiselect>
              <!-- @input="onchangeBranch();" -->

            </div>
            <div class="col-md-3">
              <label>Date Range </label>
              <date-picker v-model="daterange" value-type="format" format="DD MMM Y" range append-to-body lang="en" confirm></date-picker>
              <!-- @input="onchangeBranch();" -->

            </div>

            <!-- Apply filter -->
            <div class="col-md-2" style="width:auto; margin-top:30px;">
              <button class="btn btn-themeOrange" v-on:click="applyFilter()"> Apply Filter</button>
            </div>
            <!-- Apply filter END-->

            <!-- Clear filter -->
            <div class="col-md-2" style="width:16%;margin-top:30px;">
              <button class="btn btn-themeBrown" v-on:click="clearFilter()"> Clear Filter</button>
            </div>
            <!-- Clear filter END-->
          </div>
        </div>
      </div>
      <!--FILTER END------>
    </div>

    <!-- stats start -->
    <div class="row" style="margin-top: 44px;">
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions"
                         :series="series"></apexchart>
            </div>
            <div>
              <h4 class="mb-1 mt-1">
                       
                        <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="dashboardData.totalWalletPoints"
                                     :duration="2000"></countTo>
                        </span>
              </h4>
              <p class="text-muted mb-0">Wallet Assigned</p>
            </div>
            <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekWalletPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekWalletPercent)"></i>{{ dashboardData.lastWeekWalletPercent }}%
                    </span>
              since last week
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="orderRadial"
                         :series="orderseries"></apexchart>
            </div>
            <div>
              <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="100" :endVal="dashboardData.totalOrders" :duration="2000"></countTo>
                        </span>
              </h4>
              <p class="text-muted mb-0">Total Orders</p>
            </div>
            <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekOrdersPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekOrdersPercent)"></i>{{ dashboardData.lastWeekOrdersPercent }}%
                    </span>
              since last week
            </p>
          </div>
        </div>
      </div>
      <!-- end col-->
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions"
                         :series="series"></apexchart>
            </div>
            <div>
              <h4 class="mb-1 mt-1">

                <span data-plugin="counterup">{{
                    (dashboardData.totalMealPlanOrders) ? Math.round(dashboardData.totalMealPlanOrders) : 0
                  }}</span>
              </h4>
              <p class="text-muted mb-0">Meal Plan Orders</p>
            </div>
            <p class="text-muted mt-3 mb-0">
                      <span v-bind:class="percentColorClass(dashboardData.lastWeekMealplanOrdersPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekMealplanOrdersPercent)"></i>{{ dashboardData.lastWeekMealplanOrdersPercent }}%
                    </span>
              since last week
            </p>

          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end mt-2">
              <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
                         :series="customerseries"></apexchart>
            </div>
            <div>
              <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="dashboardData.totalEmployees" :duration="2000"></countTo>
                        </span>
              </h4>
              <p class="text-muted mb-0">Total Employees</p>
            </div>
            <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekEmpPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekEmpPercent)"></i>{{ dashboardData.lastWeekEmpPercent }}%
                    </span>
              since last week
            </p>
          </div>
        </div>
      </div>

      <!-- end col-->
    </div>
    <!-- Stats end -->

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body" style="min-height: 373px;">
            <div class="row">
              <div class="col-md-6">
                <h6>Meal Plan Statistics </h6>
              </div>
              <div class="col-md-4">

                <label class="form-label" for="formrow-endTime-input">Select Restaurant Branch*</label>
                <multiselect v-model="restBranch" :options="cafeteriaArr" track-by="restBranchID"
                             label="restaurantName" :show-labels="false" @input="changeRestBranch"
                ></multiselect>

              </div>
              <div class="col">
                <router-link :to="{ name: 'mealPlanStatisticsTable', params: { id: this.corpBranchID } }">
                  <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>
                </router-link>
              </div>
            </div>

            <!-- <MealPlanGraph :height="300" /> -->
            <!-- <v-frappe-chart v-if="mealPlanGraphArr"
              type="bar"
              :labels="mealPlanGraphArr.labels"
              :data="mealPlanGraphArr.meals"
              :colors="['red']"
          /> -->
            <apexchart type="bar" height="350" :options="chartOptionsMeal" :series="seriesMeal"></apexchart>
          </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body" style="min-height: 238px;">
            <h6> Order Statistics
              <!-- <router-link :to="{ name: 'foodcourtStatisticsTable', params: { id: this.corpBranchID } }">
              <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>
             </router-link> -->
            </h6>
            <apexchart v-if="dashboardData.foodCourtArr" class="apex-charts" height="320" type="pie" dir="ltr"
                       :series="dashboardData.foodCourtArr.series"
                       :options="dashboardData.foodCourtArr.chartOptions"></apexchart>
            <!-- dashboardData.foodCourtArr. -->
            <!-- <p style="
               margin: 50px;
               text-align: center;
           "> Coming Soon </p> -->
          </div>
        </div>


      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body" style="min-height: 238px;">
            <!--  -->
            <h6> Operational Reports Statistics
              <router-link
                  :to="{ name: 'operationalReportsForCorporate', params: { id: this.$storageData.profile.corpBranchID } }">
                <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>
              </router-link>
            </h6>
            <div v-for="(data) in dashboardData.reportsArr" :key="data.id">
              <div class="row align-items-center no-gutters mt-3">
                <div class="col-sm-6">
                  <p class="text-truncate mt-1 mb-0" :title="data.restaurantName">
                    <i class="mdi mdi-circle-medium text-primary me-2"></i> {{ data.restaurantName }}
                  </p>
                </div>
                <div class="col-sm-6" :title="data.achieved+'%'">
                  <b-progress :value="data.achieved" variant="primary" class="mt-1" height="6px"></b-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card">
             <div class="card-body" style="min-height: 415px;">
                 <h6> Subsidy Statistics</h6>
                 <p style="
                    margin: 50px;
                    text-align: center;
                "> Coming Soon </p>
             </div>
        </div> -->
      </div>

    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h6> Wallet Statistics
            <router-link :to="{ name: 'walletStatisticsTable', params: { id: this.corpBranchID } }">
              <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>
            </router-link>
          </h6>
          <v-frappe-chart
              type="line"
              :labels="dashboardData.walletStatData.series"
              :data="dashboardData.walletStatData.chartOptions"
          />
          <!-- Spline Area chart -->
          <!-- <apexchart
          class="apex-charts"
          height="350"
          type="area"
          dir="ltr"
          :series="dashboardData.walletStatData.series"
          :options="dashboardData.walletStatData.chartOptions"
          ></apexchart> -->
        </div>
      </div>
    </div>


  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import countTo from "vue-count-to";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import {VFrappeChart} from 'vue-frappe-chart';
import TermsnCondition from "@/components/termsAndCondition";

//import MealPlanGraph from "./MealPlanGraph";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    countTo,
    Multiselect,
    VFrappeChart,
    TermsnCondition,
    DatePicker
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "CloudKitch",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],

      daterange: [],//[new Date(),new Date()],
      dashboardData: [],
      mealPlanGraphArr: [],
      seriesMeal: [],
      chartOptionsMeal: {},
      branches: [],
      countryArr: [],
      country: "",
      cityArr: [],
      city: "",
      branchID: "",
      corpBranchID: "",
      cafeteriaArr: [],
      restBranch: "",
      restBranchID: "",

      // stats data
      series: [{
        data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
      }],
      chartOptions: {
        fill: {
          colors: ["#5b73e8"]
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1
          }
        },
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return "";
              }
            }
          },
          marker: {
            show: false
          }
        }
      },
      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"]
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1
          }
        },
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return "";
              }
            }
          },
          marker: {
            show: false
          }
        }
      },
      orderseries: [70],
      orderRadial: {
        fill: {
          colors: ["#34c38f"]
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%"
            },
            track: {
              margin: 0
            },
            dataLabels: {
              show: false
            }
          }
        }
      },
      customerseries: [55],
      customerRadial: {
        fill: {
          colors: ["#5b73e8"]
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%"
            },
            track: {
              margin: 0
            },
            dataLabels: {
              show: false
            }
          }
        }
      }
      /// stats data end


    };
  },
  mounted() {

    this.getCorporateCountry();
  },
  methods: {

    arrowClass(percent) {

      if (percent > 0) {
        return 'mdi mdi-arrow-up-bold me-1';
      } else {
        return 'mdi mdi-arrow-down-bold me-1';
      }
    },
    percentColorClass(percent) {

      if (percent > 0) {
        return 'text-success me-1';
      } else {
        return 'text-danger me-1';
      }
    },
    getCorporateDashboard() {
      //this.$bvModal.show("modal-termsCondition");
      this.axios.post(this.$loggedRole + "/corporateDashboard",
          {
            'corporateID': this.$storageData.profile.corporateID,
            'corpBranchID': (this.$storageData.profile.empTypeID == 6) ? this.$storageData.profile.corpBranchID : this.corpBranchID,
            'empTypeID': this.$storageData.profile.empTypeID,
            'empID': this.$storageData.profile.pid,
            'daterange': this.daterange,
            // 'restBranchID':this.restBranchID,
          })
          .then((result) => {
            this.dashboardData = result.data.data;
          });
    },
    //   getMealPlanStatsGraph(){
    //        this.axios.post(this.$loggedRole+"/getMealPlanStatsGraph",
    //         {
    //         'corpBranchID':(this.$storageData.profile.empTypeID==6) ? this.$storageData.profile.corpBranchID : this.corpBranchID,
    //         'restBranchID':this.restBranchID,
    //         })
    //           .then((result)=>{
    //             this.dashboardData.mealPlanFinalArr = result.data.data;
    //         });
    //   },
    mealPlanGraph() {
      this.axios.post(this.$loggedRole + "/mealPlanGraph",
          {
            'corpBranchID': (this.$storageData.profile.empTypeID == 6) ? this.$storageData.profile.corpBranchID : this.corpBranchID,
            'restBranchID': this.restBranchID,
            'daterange': this.daterange,
          })
          .then((result) => {
            // this.mealPlanGraphArr = result.data;
            // console.log(result.data);
            //    return;
            this.seriesMeal = result.data.meals,
                this.chartOptionsMeal = {
                  chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                      show: true
                    },
                    zoom: {
                      enabled: true
                    }
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: result.data.labels,
                    // categories: ["25/08/2021", "26/08/2021", "27/08/2021", "28/08/2021", "29/08/2021", "30/08/2021", "31/08/2021","01/09/2021","02/09/2021","03/09/2021","04/09/2021","05/09/2021","06/09/2021"],
                  },
                  // yaxis: {
                  //   title: {
                  //     text: '$ (thousands)'
                  //   }
                  // },
                  fill: {
                    opacity: 1
                  },
                  // tooltip: {
                  // y: {
                  //     formatter: function (val) {
                  //     return "$ " + val + " thousands"
                  //     }
                  // }
                  // }
                }
          });
    },
    getCorporateCountry() {
      this.axios
          .post(this.$loggedRole + "/getCorporateCountry", {
                corporateID: this.$storageData.profile.corporateID,
                flag: (this.$storageData.profile.empTypeID == 6) ? 0 : 1, // for all dropdown in only dashboards
              }
          )
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = (this.countryArr) ? this.countryArr[0] : "";
            this.getCorporateCities();

          });
    },
    getCorporateCities() {
      this.axios
          .post(this.$loggedRole + "/getCorporateCities", {
                corporateID: this.$storageData.profile.corporateID,
                country: (this.country) ? this.country.country : "",
                flag: (this.$storageData.profile.empTypeID == 6) ? 0 : 1,
                corpBranchID:this.$storageData.profile.empTypeID == 6 ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = (this.cityArr) ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();

          });
    },
    getCorporateBranchByCorpID() {

      this.axios
          .post(this.$loggedRole + "/getCorporateBranchByCorpID",
              {
                corporateID: this.$storageData.profile.corporateID,
                city: (this.city) ? this.city.city : "",
                flag: (this.$storageData.profile.empTypeID == 6) ? 0 : 1,
                corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {

            this.branches = response.data.data;
            this.branchID = (this.branches) ? this.branches[0] : "";
            this.corpBranchID = (this.branchID) ? this.branchID.corpBranchID : "";
            this.getCorporateDashboard();
            this.getCorporateCafeteria(this.corpBranchID);
          });
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID); // passing corporate Branch ID
      this.getCorporateDashboard();
      this.mealPlanGraph();

    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }

      this.corpBranchID = this.branchID.corpBranchID;

      this.getCorporateCafeteria(this.corpBranchID); // passing corporate Branch ID
      this.getCorporateDashboard();
      this.mealPlanGraph();
    },
    clearFilter() {
      this.corpBranchID = this.branchID.corpBranchID;
      this.daterange = [];
      this.getCorporateCountry();
      this.mealPlanGraph();
    },
    changeRestBranch() {
      this.restBranchID = this.restBranch.restBranchID;
      this.mealPlanGraph();
    },

    getCorporateCafeteria(corpBranchID) {

      this.axios.post(this.$loggedRole + "/getCorporateCafeteria", {'corpBranchID': corpBranchID})
          .then((result) => {
            this.cafeteriaArr = result.data.data;
            this.restBranch = (this.cafeteriaArr) ? this.cafeteriaArr[0] : '';
            this.restBranchID = this.restBranch.restBranchID;
            this.mealPlanGraph();


          });
    },


    //   test(){
    //       const today = new Date();
    //       alert(today);
    // 				const date = today.getFullYear()+'-'+(today.getMonth()-0)+'-'+today.getDate()+today.getTimezoneOffset();
    // 				alert(date);
    //   }
  },

};
</script>

